<template>
  <div class="login">
    <div class="header">
      <div class="container">
        <div class="logo"><img src="../assets/logo.png" alt="众为资本" /></div>
        <ChangeLanguage />
      </div>
    </div>
    <div class="form-box reset-password" :class="{ step2: step == 2 }">
      <div class="back-login" @click="backLogin"><i class="el-icon-arrow-left"></i>{{ $t('login.backLogin') }}</div>
      <h1>{{ $t('login.passwordRecovery') }}</h1>
      <div class="email-value" v-if="emailForm.email && step == 2">{{ emailForm.email }}</div>
      <el-form :model="emailForm" ref="emailForm" class="form-login" v-show="step === 1">
        <el-form-item
          prop="email"
          :rules="[
            { required: true, message: $t('login.emailError'), trigger: 'blur' },
            { type: 'email', message: $t('login.emailError2'), trigger: ['blur', 'change'] },
          ]"
        >
          <el-input v-model="emailForm.email" :placeholder="$t('login.email')" class="email"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="btn-block" @click="nextStep('emailForm')">{{ $t('login.next') }}</el-button>
        </el-form-item>
      </el-form>
      <el-form :model="resetForm" ref="resetForm" class="form-login" v-show="step === 2">
        <el-form-item prop="code" :rules="[{ required: true, message: $t('login.verificationCodeError'), trigger: 'blur' }]">
          <el-input :placeholder="$t('login.verificationCode')" class="code" v-model="resetForm.code"></el-input>
          <div class="input-suffix">
            <el-button type="text" :disabled="!disabledCodeBtn" @click="sendVerifycode()">{{ codeText }}</el-button>
          </div>
        </el-form-item>
        <el-form-item
          prop="password"
          :rules="[
            { required: true, message: $t('login.passwordError'), trigger: 'blur' },
            { pattern: /^[A-Za-z0-9]{7,32}$/, message: $t('login.passwordTip') },
          ]"
        >
          <el-input type="password" :maxlength="32" :placeholder="$t('login.password')" class="password" v-model="resetForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="btn-block" @click="submitForm('resetForm')">{{ $t('Confirm') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <Footer/>
  </div>
</template>

<script>
import api from '@/api'
import ChangeLanguage from '@/components/ChangeLanguage.vue'
import Footer from '@/layout/components/Footer.vue'

export default {
  components: {
    ChangeLanguage,
    Footer
  },
  data() {
    return {
      step: 1,
      emailForm: {
        email: '',
      },
      resetForm: {
        code: '',
        password: '',
      },
      codeText: this.$t('login.getVerificationCode'),
      disabledCodeBtn: true,
    }
  },
  methods: {
    backLogin() {
      this.$router.push('/login')
    },
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.step = 2
        } else {
          return false
        }
      })
    },
    sendVerifycode() {
      api.getValidCodeByEmail(this.emailForm.email).then((res) => {
        console.log(res)
        this.countDown(60)
        this.$message({
          message: this.$t('login.codeSent'),
          type: 'success',
        })
      })
    },
    countDown(time) {
      // 倒计时方法
      if (time === 0) {
        this.disabledCodeBtn = true
        this.codeText = this.$t('login.Resend')
        return
      } else {
        this.disabledCodeBtn = false
        this.codeText = time + 's'
        time--
      }
      setTimeout(() => {
        this.countDown(time)
      }, 1000)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.resetPasswordWithEmail({ ...this.emailForm, ...this.resetForm }).then((res) => {
            console.log(res)
            this.$router.push('/login')
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.reset-password {
  padding-top: 33px;
  padding-bottom: 80px;
  .back-login {
    margin-bottom: 40px;
    color: #5a5a5a;
    cursor: pointer;
  }
  &.step2 {
    padding-bottom: 25px;
    h1 {
      margin-bottom: 0;
    }
    .email-value {
      margin-bottom: 50px;
    }
  }
}
</style>
