var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('ChangeLanguage')],1)]),_c('div',{staticClass:"form-box reset-password",class:{ step2: _vm.step == 2 }},[_c('div',{staticClass:"back-login",on:{"click":_vm.backLogin}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(_vm._s(_vm.$t('login.backLogin')))]),_c('h1',[_vm._v(_vm._s(_vm.$t('login.passwordRecovery')))]),(_vm.emailForm.email && _vm.step == 2)?_c('div',{staticClass:"email-value"},[_vm._v(_vm._s(_vm.emailForm.email))]):_vm._e(),_c('el-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],ref:"emailForm",staticClass:"form-login",attrs:{"model":_vm.emailForm}},[_c('el-form-item',{attrs:{"prop":"email","rules":[
          { required: true, message: _vm.$t('login.emailError'), trigger: 'blur' },
          { type: 'email', message: _vm.$t('login.emailError2'), trigger: ['blur', 'change'] },
        ]}},[_c('el-input',{staticClass:"email",attrs:{"placeholder":_vm.$t('login.email')},model:{value:(_vm.emailForm.email),callback:function ($$v) {_vm.$set(_vm.emailForm, "email", $$v)},expression:"emailForm.email"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary"},on:{"click":function($event){return _vm.nextStep('emailForm')}}},[_vm._v(_vm._s(_vm.$t('login.next')))])],1)],1),_c('el-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 2),expression:"step === 2"}],ref:"resetForm",staticClass:"form-login",attrs:{"model":_vm.resetForm}},[_c('el-form-item',{attrs:{"prop":"code","rules":[{ required: true, message: _vm.$t('login.verificationCodeError'), trigger: 'blur' }]}},[_c('el-input',{staticClass:"code",attrs:{"placeholder":_vm.$t('login.verificationCode')},model:{value:(_vm.resetForm.code),callback:function ($$v) {_vm.$set(_vm.resetForm, "code", $$v)},expression:"resetForm.code"}}),_c('div',{staticClass:"input-suffix"},[_c('el-button',{attrs:{"type":"text","disabled":!_vm.disabledCodeBtn},on:{"click":function($event){return _vm.sendVerifycode()}}},[_vm._v(_vm._s(_vm.codeText))])],1)],1),_c('el-form-item',{attrs:{"prop":"password","rules":[
          { required: true, message: _vm.$t('login.passwordError'), trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]{7,32}$/, message: _vm.$t('login.passwordTip') },
        ]}},[_c('el-input',{staticClass:"password",attrs:{"type":"password","maxlength":32,"placeholder":_vm.$t('login.password'),"autocomplete":"off"},model:{value:(_vm.resetForm.password),callback:function ($$v) {_vm.$set(_vm.resetForm, "password", $$v)},expression:"resetForm.password"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"btn-block",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('resetForm')}}},[_vm._v(_vm._s(_vm.$t('Confirm')))])],1)],1)],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"众为资本"}})])
}]

export { render, staticRenderFns }